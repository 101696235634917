<template>
  <div class="user-page" style="width: 30%">
    <el-form :model="userForm" label-width="80px">
      <el-form-item label="用户名">
        <el-input v-model="userForm.username" type="text"/>
      </el-form-item>
      <el-form-item v-model="userForm.password" label="密码">
        <el-input v-model="userForm.password" type="password" show-password/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        userForm: {
          id: null,
          username: '',
          password: ''
        }
      }
    },
    mounted() {
      this.getUserInfo()
    },
    methods: {
      getUserInfo() {
        axios.get('/api/user').then(data => {
          const { id, username, password } = data.data
          this.userForm.id = id
          this.userForm.username = username
          this.userForm.password = password
        })
      },
      
      updateUserInfo() {
        axios.post('/api/user', {...this.userForm}).then(data => {
          if (data.data === 'success') {
            this.$notify.success('修改成功')
          } else {
            this.$notify.error('修改失败')
          }
        })
      },
      
      handleSubmit() {
        const { username, password } = this.userForm
        if (username.length === 0 || password.length === 0) {
          this.$notify.warning('用户名或密码不能为空')
          return
        }
        this.updateUserInfo()
      }
    }
  }
</script>

<style lang="less">
  .user-page {
    width: 300px;
  }
</style>
